import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import LeftFeatureVideo from '../components/left-feature-video';
import FeaturePararaph from '../components/feature-paragraph'
import {KGlackinTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import FreeTrialButton from '../components/free-trial-button';

import messengerSrc from '../images/online-messenger.svg';
import instantMessengerMobileSrc from '../images/instant-messenger-mobile.png';
import individualInstantMessengerMobileSrc from '../images/individual-messenger-chat.png';
import messengerWebPreviewSrc from '../images/messenger-web-preview.png';

const EmployeeInstantMessenger = () => (
  <Layout>
    <SEO 
    	title="Built-In Employee Instant Messenger | Chat with your employees individually or in a group"
    	description="Communicate with your employees in real-time using TimeKeeper's web and mobile instant messenger."
    />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            <span className="text-highlight-600">Message your employees</span>
			            <br />
			            in real-time
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			          	Keep your team informed with TimeKeeper's employee instant messenger! Available on both the web portal and on the mobile app.
			          </p>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            <i>The messsenger is a paid add on at £39/month.</i>
			          </p>
			          <FreeTrialButton />
			          <AppStoreIcons />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={messengerSrc} alt="Instant Messenger Chat Icon" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <LeftFeature 
				    	sectionTitle="Team Chat" 
				    	title="Communicate with your team instantly" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Do you need to send a message to all employees or perhaps to a specific team working on a project?
						        </FeaturePararaph>
						        <FeaturePararaph>
						          If so, you can create a group chat within TimeKeeper. This feature allows you to message your entire team instantly and maintain constant communication about what's happening in your business.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={instantMessengerMobileSrc}
				    	imgAlt="Paperless digital visitor book"
				    />
				     <RightFeature 
				    	sectionTitle="Private Communication with an individual employee" 
				    	title="Private Individual Employee Chat" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						         Perhaps you need to stay in touch with a specific employee? Simply create a private chat between you and them.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          This enables both of you to communicate in real-time about any topic that needs to be discussed!
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={individualInstantMessengerMobileSrc}
				    	imgAlt="Individual private chat with Joe Swanson on TimeKeeper instant messenger"
				    />
				    
				    <RightFeature 
				    	sectionTitle="Built for business communication" 
				    	title="Modern Workplace Messenger 💬" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          The TimeKeeper instant messenger provides all the essential features for a contemporary business chat experience. Office staff can communicate using the web messenger, while mobile staff can stay connected through the mobile app.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Employees can directly mention colleagues, react to messages, see who is online, reply to individual messages, use moderation features, and much more to ensure a seamless chat experience. This fully-fledged chat platform is available for all your employees with unlimited messages, for one simple monthly fee.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={messengerWebPreviewSrc}
				    	includeShadow={true}
				    	imgAlt="Modern Employee Messenger Notification"
				    />
				    <LeftFeatureVideo 
				    	sectionTitle="Simple, secure, portable chat" 
				    	title="Watch it in action" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          After enabling the messenger add-on for your account, employees can send and receive messages with other staff members. 
						        </FeaturePararaph>
						        <FeaturePararaph>
						         You can set rules to determine which employees can communicate with each other and who can create group chats. This add-on is available after upgrading your TimeKeeper trial.
						        </FeaturePararaph>
						    </div>
				    	}
				    	rightChild={
				    		<div className="flex w-full justify-center mt-4">
				              <iframe
				                width="560" 
				                height="315"
				                src="https://www.youtube.com/embed/Dg4STIiOdpE"
				                title="Employee Instant Messenger"
				                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				                frameBorder="0"
				                webkitallowfullscreen="true"
				                mozallowfullscreen="true"
				                allowFullScreen
				              />
				            </div>
				    	} />
				</div>
				<KGlackinTestimonial />
				<FreeTrial part1={
					<span>Upgrade your business <br /> communication now!</span>
				} />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default EmployeeInstantMessenger;
