import React from 'react';

const LeftFeatureVideo = ({sectionTitle, title, body, rightChild}) => (
	<div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <div className="text-highlight-500 text-base font-bold mb-4">
        	{sectionTitle}
        </div>
        <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-snug">
          {title}
        </h4>
        {body}
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0">
        {rightChild}
      </div>
    </div>
)	

export default LeftFeatureVideo;